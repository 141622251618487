<template>
  <div class="font-s-14">
    <div>
      <loading v-if="loading"></loading>
      <div v-else>
        <div v-if="list.length > 0">
          <van-list v-model="loading2" :finished="finished" finished-text="没有更多了" @load="nextPage">
            <goods-list-com style="margin-top: 0.8rem;" :DataList="list"> </goods-list-com>
          </van-list>
        </div>
        <div class="empt" v-else>
        </div>
      </div>
    </div>
    <!-- <seachview v-else ref="seach" :value="value" @value="seachvalue" :type="0"></seachview> -->
  </div>
</template>

<script>
import { Toast } from "vant";
import HeadChild from "../../components/HeadChild";
import NaigateTool from '../../components/index/NavigateTool';
import Tab from "../../components/goodlist/Tab.vue";
import SecondTab from "../../components/goodlist/SecondTab.vue";
import Seachview from "../../components/index/Seachview.vue";
import GoodsListCom from "../../components/GoodsListCom.vue";
let initViewfinish = false
export default {
  name: "LableList",
  props: {},
  data() {
    return {
      boo2: true,
      list: [],
      currentPage: 0, // 当前页码
      pageSize: 10, // 每页个数
      totalSize: 10, // 总个数
      loading2: false, // 加载下一页
      finished: false, // 是否加载完了
      refreshing: false,
      checkList: [], // 列表数据
      tabindexL: 1,
      loading: false,
      category: [],
      tempList: [],
      value: null,
      // 刷新
      isLoading: false,
      form: {
        hotType: '',
        cardId: "",
        current: 1, // 当前页码
        size: 10, // 每页个数
        productName: "",
        category1Id: "",// 二级分类菜单
        cityCode: '',
        upgradeFlag: localStorage.getItem("upgradeFlag"),
        bathId: localStorage.getItem("bathId"),
      },
    };
  },
  components: {
    HeadChild,
    Tab,
    SecondTab,
    NaigateTool,
    GoodsListCom,
    Seachview
  },
  methods: {
    // 返回
    goBack() {
      if (this.boo2) {
        this.$router.back();
      } else
        this.boo2 = true;
    },
    onSearch() {
      // this.$refs.seach.seach(this.value);
      this.boo2 = false;
    },
    //子组件分发的事件  点击历史记录重新给输入框赋值
    seachvalue(val) {
      this.value = val;
    },
    clossSecondPop() {
      if (this.$refs.secondTab) {
        this.$refs.secondTab.clossPop()
      }
    },
    nextPage() { // 加载下一页
      if (this.list.length >= 10) {
        this.loading = false
        this.form.current += 1
        this.getData()
      }
    },
    getTabIndex(index) {
      this.tabindexL = index
      Toast("刷新成功" + index);
    },
    // 刷新
    onRefresh() {
      setTimeout(() => {
        Toast("刷新成功");
        this.getData();
        this.isLoading = false;
      }, 1000);
    },
    getData() {
      this.$api
        .queryProductInfoHomeNew(this.form)
        .then((res) => {
          if (res.code == 1) {
            this.$Toast(res.msg);
            return
          }
          if (this.form.current == 1) {
            this.list = res.data.records
          } else {
            this.list.push(...res.data.records)
          }
          if (this.form.current >= res.data.pages) {
            this.finished = true
          }
          this.loading = false
          this.isLoading = false
          this.loading2 = false
        })
        .catch((err) => {
        });
    },
    scrollToAll(index) {
      this.tempList = this.category[index].categoryTwo
      this.list = this.tempList[0].productInfoList
      this.$nextTick(() => {
        this.$refs.secondTab.clickCard(0)
      })
    },
    scrollTo(index) {
      this.list = this.tempList[index].productInfoList
    },
    goDetail(val) {
      //  传递参数
      this.$router.push({
        path: "/Details",
        query: {
          id: val.id,
          specId: val.specId,
          brandId: val.brandId,
        },
      });
    },
    // 位置选择
    Switch() {
      this.$router.push("SelectAdderss");
    },
    // 调用子组件的方法
    seach() {
      this.$refs.seach.seach(this.value);
    },
    // 返回
    initView() {
      this.loading = true
      this.form.hotType = this.$route.query.type
      this.form.id = this.$route.query.activityId
      this.form.cardId = localStorage.getItem('cardId')
      this.form.cityCode = localStorage.getItem("cityCode");
      this.getData()
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.path !== "/Details") {
      next((vm) => {
        vm.$nextTick(() => {
          vm.initView()
          document.documentElement.scrollTop = 0;
          document.body.scrollTop = 0
        })
      });
    } else {
      next();
    }
  },
  mounted() {
  },
  computed: {},
};
</script>

<style lang='scss' scoped>
.container-water-fall {

  /* // padding: 0 28px; */
  padding: 0 3%;
  width: 100vw;
  box-sizing: border-box;
  /* background: #fafafa !important; */
}

.cell-item {
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  box-sizing: border-box;
  margin: 5% 0;
}

.cell-item img {
  width: 100%;
  height: auto;
  display: block;
}

.head {
  padding: 0.5rem 0.2rem;
  height: 2rem;
  background-color: white;
  overflow: hidden;
}

.empt {
  display: flex;
  flex-direction: column;
  height: 60vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: #000;
}

.empty {
  transform: translateY(50%);
}

/*瀑布流对应布局开始*/
.main {
  height: max-height;
  flex: auto;
  overflow-y: auto;
  overflow-x: hidden;

  /*瀑布流对应布局开始*/
  .waterfall-item {
    margin: 5px;
    background: white;
    box-sizing: border-box;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    padding-bottom: 1rem;
  }

  .waterfall-item img {
    width: 100%;
    display: block;
  }

  .bounceIn {
    animation-name: bounceIn;
    animation-duration: .6s;
    animation-timing-function: linear;
    transform-origin: center center;
  }

  @keyframes bounceIn {
    0% {
      opacity: 0;
      -webkit-transform: scale(.3);
      -ms-transform: scale(.3);
      transform: scale(.3);
    }

    50% {
      opacity: 1;
      -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
      transform: scale(1.05);
    }

    70% {
      -webkit-transform: scale(.9);
      -ms-transform: scale(.9);
      transform: scale(.9);
    }

    100% {
      opacity: 1;
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }
  }
}



.img {
  height: 2rem;
  width: 2rem;
  border-radius: 0.3rem;
  vertical-align: middle;
}

.select-tag {
  background-color: #D23B2E;
  border-radius: 1rem;
  padding: 0.1rem 0.5rem;
  color: white;
}

.unselect-tag {
  background-color: white;
  color: #666;
}


/*瀑布流对应布局结束*/
</style>