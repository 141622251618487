<template>
  <!-- 图片导航栏 -->
  <div class="page">
    <div ref="wrappers" class="wrappers">
      <div class="flex" style="flex: 1;margin: 0 0rem;">
        <div v-for="(item, index) in category" :key="index" class="item" @click="queryTopicBoolean(item)">
          <img v-lazy="item.showImgId?.showImg" alt="" class="img" @load="loadfinish" />
          <div style="font-size: 0.9rem;margin: 0.2rem 0;">{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BScroll from "better-scroll";
export default {
  name: "",
  props: {
    category: {
      type: Array,
    },

  },
  data() {
    return {
      scrollb: null
    };
  },
  components: {},
  methods: {
    // 横向滚动
    scroll() {
      this.$nextTick(() => {
        this.scrollb = new BScroll(this.$refs.wrappers, {
          startX: 0, // 配置的详细信息请参考better-scroll的官方文档，这里不再赘述
          click: false,
          scrollX: true,
          scrollY: false,
          mouseWheel: true,//开启鼠标滚轮
          disableMouse: false,//启用鼠标拖动
          disableTouch: false,//启用手指触摸
          // 在手机端 让手指放在滚动区域也可以竖向滚动
          eventPassthrough: "vertical",
        });
      });
    },
    loadfinish() {
      this.scrollb && this.scrollb.refresh()
    },
    queryTopicBoolean(item) {
      let form = {
        cardId: localStorage.getItem("cardId"),
        cityCode: localStorage.getItem("cityCode"),
        upgradeFlag: localStorage.getItem("upgradeFlag"),
        bathId: localStorage.getItem("bathId"),
        size: 10,
        current: 1,
        hotType: "D",
        id: item.showImgId.id
      }
      this.$api
        .queryTopicBoolean(form)
        .then((res) => {
          if (res.data) {
            if (res.data.flag) {
              item.flag = true
              item.showType = res.data.showType
              item.productId = res.data.productId
              item.brandId = res.data.brandId
              this.goClass(item)
            } else {
              Toast('敬请期待')
            }
          } else {
            Toast(res.msg)
          }
        })
        .catch((err) => {
        });
    },
    goClass(val) {
      if (!val.flag) {
        this.$Toast('敬请期待')
        return
      }
      if (val.showType == '1') {
        this.$router.push({
          path: "/LableList",
          query: { activityId: val.showImgId.id, type: 'D' }
        });
      } else if (val.showType == '2') {
        this.$router.push({
          path: "/LableClassList",
          query: { activityId: val.showImgId.id, type: 'D' }
        });
      } if (val.showType == '3') {
        if (val.productId) {
          //  传递参数
          this.$router.push({
            path: "/Details",
            query: {
              id: val.productId,
              brandId: val.brandId,
            },
          });
        } else {
          this.$Toast('敬请期待')
        }
      }


    },
  },
  mounted() {
    // console.log(this.category1);
    this.$nextTick(() => {
      this.scroll();
    });
  },
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.page {
  .wrappers {
    display: flex;
    flex-direction: row;
    overflow: hidden;

    .item {

      border-radius: 0.5rem;
      width: 25%;
      text-align: center;
      padding-bottom: 0.5rem;


      .img {
        width: 4rem;
        height: 4rem;
        border-radius: 0.3rem;
        margin-top: 0rem;
      }
    }

  }
}
</style>