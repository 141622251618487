\


<template>
  <!-- 图片导航栏 -->
  <div class="page">
    <div class="flex container imageDiv">
      <!-- flex-1 -->
      <div v-for="(item, index) in formatCategory" :key="index" class="t-a-c imageDivItem" @click="goClass(item)">
        <img :src="item.icon" alt="" class="img" />
        <div class="divText">{{ item.name }}</div>
      </div>
    </div>
    <!-- 下部分轮播显示 -->
    <div style="padding-bottom:0.5rem;"
      v-if="bottomCategory.length > 0 && bottomCategory[0].length > 0 && bottomCategory[0][0].length > 0">
      <van-swipe @change="onChange" :loop="false" style="height: 100%;" ref="mySwipe" :duration="duration">
        <van-swipe-item>
          <div class="flex" style="display: flex;flex-direction: column;padding-bottom: 0.5rem;">
            <div class="flex container2">
              <div v-for="(item, index) in bottomCategory[0][0]" :key="index" class="imageDivItem t-a-c"
                @click="goClass(item)">
                <img :src="item.icon" alt="" class="img" />
                <div class="divText">{{ item.name }}</div>
              </div>
            </div>
            <div v-if="bottomCategory[0][1].length > 0" class="flex container2">
              <div v-for="(item, index) in bottomCategory[0][1]" :key="index" class="imageDivItem t-a-c"
                @click="goClass(item)">
                <img :src="item.icon" alt="" class="img" />
                <div class="divText">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </van-swipe-item>
        <van-swipe-item v-if="bottomCategory[1][0].length > 0">
          <div class="flex " style="display: flex;flex-direction: column;padding-bottom: 0.5rem;">
            <div class="flex container2">
              <div v-for="(item, index) in bottomCategory[1][0]" :key="index" class="imageDivItem t-a-c"
                @click="goClass(item)">
                <img :src="item.icon" alt="" class="img" />
                <div class="divText">{{ item.name }}</div>
              </div>
            </div>
            <div v-if="bottomCategory[1][1].length > 0" class="flex container2">
              <div v-for="(item, index) in bottomCategory[1][1]" :key="index" class="imageDivItem t-a-c"
                @click="goClass(item)">
                <img :src="item.icon" alt="" class="img" />
                <div class="divText">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </van-swipe-item>
        <template #indicator>
          <ul class="indicators" v-if="bottomCategory[1][0].length > 0">
            <li class="indicator" v-for="(item, index) in bottomCategory" :key="index"
              :class="{ active: checkActive(index) }"></li>
          </ul>
        </template>
      </van-swipe>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavImage",
  props: {
    cardShowFlag: {
      type: String | Number,
      default: null
    }
  },
  data() {
    return {
      duration: 500,
      current: 0,
      category: [
        {
          icon: require("@/assets/images/home_caka.png"),
          id: "cake",
          name: "蛋糕"
        },
        {
          icon: require("@/assets/images/home_movie.png"),
          id: "movie",
          name: "电影"
        }
      ],
      reqCategory: [], // 查询返回的分类数据
      bottomCategory: [],
      containMovie: false,//电影是否展示
      containCake: false,//蛋糕是否展示
      formatCategory: [],
      dquData: [],
    };
  },
  components: {},
  computed: {
  },
  methods: {
    setIndex() {
      if (this.current > 0) {
        this.duration = 0
        this.$refs.mySwipe.swipeTo(this.current);
      }
      var timer = setInterval(() => {
        this.duration = 500
        clearInterval(timer);
      }, 200)
    },

    onChange(index) {
      this.current = index;
    },
    checkActive(index) {
      return index === this.current;// current为监听的轮播index
    },
    async init() {

      const res1 = await this.$api.selectPlatformTwoCategory(localStorage.getItem("bathId"), localStorage.getItem("upgradeFlag"), localStorage.getItem("cityCode"));
      if (res1.code === 0) {
        const res = await this.$api.queryCardOneCategoryTop(localStorage.getItem("bathId"), localStorage.getItem("upgradeFlag"), localStorage.getItem("cityCode"));
        if (res) {
          let TotleData = []
          this.formatCategory = []
          this.containMovie = res.data.containMovie
          this.containCake = res.data.containCake
          TotleData.push(...res1.data)
          if (this.containCake) {
            TotleData.push(this.category[0])
          }
          if (this.containMovie) {
            TotleData.push(this.category[1])
          }
          //增加判断,只配置餐饮自提中的分类,不显示问题
          if (res.data.otherCategories) {
            TotleData.push(...res.data.otherCategories)
          }
          // if (!this.containMovie && !this.containCake) { //没有电影 并且没有蛋糕
          //   this.formatCategory.push(...this.reqCategory.slice(0, 5))
          //   this.bottomCategory = [[this.reqCategory.slice(5, 10), this.reqCategory.slice(10, 15)], [this.reqCategory.slice(15, 20), this.reqCategory.slice(20, 25)]]
          // } else if (this.containMovie && !this.containCake) { //有电影 没蛋糕
          //   this.formatCategory.push(...this.reqCategory.slice(0, 4))
          //   this.formatCategory.push(this.category[1])
          //   this.bottomCategory = [[this.reqCategory.slice(4, 9), this.reqCategory.slice(9, 14)], [this.reqCategory.slice(14, 19), this.reqCategory.slice(19, 24)]]
          // } else if (!this.containMovie && this.containCake) { //有蛋糕 没电影
          //   this.formatCategory.push(this.category[0])
          //   this.formatCategory.push(...this.reqCategory.slice(0, 4))
          //   this.bottomCategory = [[this.reqCategory.slice(4, 9), this.reqCategory.slice(9, 14)], [this.reqCategory.slice(14, 19), this.reqCategory.slice(19, 24)]]
          // } else {
          //   this.formatCategory.push(this.category[0])
          //   this.formatCategory.push(...this.reqCategory.slice(0, 3))
          //   this.formatCategory.push(this.category[1])
          //   this.bottomCategory = [[this.reqCategory.slice(3, 8), this.reqCategory.slice(8, 13)], [this.reqCategory.slice(13, 18), this.reqCategory.slice(18, 23)]]
          // }
          // console.log(' TotleData---  ', TotleData);
          this.formatCategory = TotleData.slice(0, 5)
          this.bottomCategory = [[TotleData.slice(5, 10), TotleData.slice(10, 15)], [TotleData.slice(15, 20), TotleData.slice(20, 25)]]

        }
      }


    },
    goClass(record) {
      const { id, name, jumpPageFlag } = record;
      if (jumpPageFlag) {
        this.$api
          .getEntrance(jumpPageFlag)
          .then((res) => {
            if (res.code === 0) {
              window.location.href = res.data;
            } else {
              this.$Toast.fail(res.msg)
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        let path = "/GoodsList";
        switch (id) {
          case "cake": // 蛋糕
            path = "/CakaHome";
            break;
          case "movie": // 蛋糕
            path = "/MoviesHome";
            break;
          default:
            break;
        }
        this.$router.push({ path, query: { indexName: name } });
      }

    }
  },
  mounted() {
    // this.init();
  },
  watch: {
    '$route': function () {
      this.setIndex()
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  // margin: 0.5rem;
  // margin-right: 20px;
  margin-top: 1.125rem;
  // margin-left: 0.5rem;
  // margin-right: 0.5rem;
  border-radius: 0.5rem;
  background: #ffffff;
  margin-bottom: 0px;
  width: 100%;
  padding-top: 1rem;

  .container {
    // padding: 1px 1px 1px;
    // padding-left: 10px;
    // padding-right: 10px;
    // margin: 10px;
    // margin-top: 0.625rem;
    margin-bottom: 3px;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around;
    width: 100%;

    >div {
      // margin: 5px;
      // margin: 2.333vw;
      margin-bottom: 0.1rem;
    }

    .img {
      width: 3rem;
      height: 3rem;
      border-radius: 0.3rem;
    }
  }

  .container2 {
    width: 100%;

    >div {
      margin: 5px 0;
    }

    .img {
      width: 3rem;
      height: 3rem;
      border-radius: 0.5rem;
    }
  }
}

//自定义指示器
.indicators {
  position: absolute;
  bottom: 0px;
  left: 50%;
  display: flex;
  transform: translateX(-50%);

  .indicator {
    width: 3px;
    height: 3px;
    background-color: #c90c0c;
    border-radius: 50%;
    opacity: 0.4;

    transition: opacity 0.5s, background-color 0.5s;

    &:not(:last-child) {
      margin-right: 4px;
      margin-top: 5px;
    }

    &:last-child {
      margin-right: 4px;
      margin-top: 5px;
    }

    &.active {
      width: 10px;
      height: 3px;
      border-radius: 2px;
      opacity: 0.8;
    }
  }
}

.divText {
  font-size: 0.75rem;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
}

.imageDivItem {
  // width: 14.3%;
  width: 20%;
  // margin: 0 5px 0 5px;
}
</style>
