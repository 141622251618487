<template>
  <div class="container app-wrapper">
    <head-index v-if="boo2 && headData" :headData="headData"
      :style="{ height: bannerHeight + 'px', marginTop: '-1rem' }" :show-indicators="false"></head-index>
    <div style="position: absolute; top: 0;z-index: 2; width: 100%;">
      <van-sticky :offset-top="0">
        <head-child :class="boo2 ? 'head' : 'head2'">
          <template #left>
            <div class="flex jcc t-a-c" v-if="boo2" @click="Switch">
              <!-- <van-loading type="spinner" v-if="boo" /> -->
              <div class="ellipsis mr-5">
                {{ address }}
              </div>
              <div @click="Switch">
                <i class="iconfont icon-iconfontarrows1"></i>
              </div>
            </div>
            <div v-else @click="goback">
              <van-icon size="1.5rem" name="arrow-left" />
            </div>
          </template>
          <template #middle>
            <van-search v-model="value" placeholder="搜索商品" @focus="seach1" />
          </template>
          <template #right>
            <div @click="seach">搜索</div>
            <!-- <div @click="delSeach" v-else>取消</div> -->
          </template>
        </head-child>
      </van-sticky>
    </div>
    <!-- 正常页面 -->
    <div v-if="boo2" style="position: absolute; top: 16rem; z-index: 1; width: 100%;">
      <div
        style="background-color: #F8F8F8; padding: 1rem 0  0  0; padding-bottom: 3rem; border-top-right-radius: 1rem; border-top-left-radius: 1rem; ">
        <nav-image v-if="category" :category="category" style="margin: 0  0.5rem;"></nav-image>
        <lable-image style=" margin-left: 0.5rem; margin-right: 0.5rem;" v-if="lableData"
          :data-list="lableData"></lable-image>
        <div>
          <van-sticky :offset-top="48" style="margin-bottom: 0.5rem;">
            <!-- <van-tabs @click="getTabIndex" v-model="navIndex">
              <van-tab v-for="(item, index) in dquData" :title="item.name" :key="index" />
            </van-tabs> -->
            <CakaTab  :title="dquData" ref="firstTab" @scrollToAll="getTabIndex"></CakaTab>
          </van-sticky>
          <van-list v-model="loading2" :finished="finished" finished-text="没有更多了" @load="nextPage">
            <goods-list-com :DataList="list"> </goods-list-com>
          </van-list>
        </div>
      </div>
    </div>
    <!-- 搜索页面 -->
    <seachview v-else ref="seach" style="margin-top: 3rem;" :value="value" @value="seachvalue" :type="1"></seachview>
    <!-- </van-pull-refresh> -->
  </div>
</template>

<script>
import { Toast } from "vant";
import HeadChild from "../../components/HeadChild";
import Recommended from "../../components/index/Recommended.vue";
import NavImage from "../../components/cake/NavImage.vue";
import LableImage from "../../components/cake/LableImage.vue";
import IndexFloor from "../../components/index/IndexFloor.vue";
import HeadIndex from "../../components/index/HeadIndex.vue";
import NaigateTool from '../../components/index/NavigateTool';
import Seachview from "../../components/index/Seachview.vue";
import GoodsListCom from "../../components/GoodsListCom.vue";
import vueWaterfallEasy from "vue-waterfall-easy";
import CakaTab from "../../components/cake/CakaTab.vue";
let initViewfinish = false

export default {
  name: "Home",
  props: {},
  data() {
    return {
      // 容器宽度百分比
      width: 100,
      // 滑轮值
      range: 100,
      //是否根据容器尺寸自动计算重绘
      autoResize: true,
      //列数
      col: 2,
      navIndex: 0,
      //是否始终填满容器
      fillBox: false,
      // //列宽-有指定列数则此属性失效
      // colWidth: (pc ? window.innerHeight : window.innerWidth) / 2,
      // // 列数和列宽模式切换
      // mode: 0,
      list: [],
      recoData: [],
      hotdata: [],
      loading2: false, // 加载下一页
      finished: false, // 是否加载完了
      refreshing: false,
      checkList: [], // 列表数据
      tabindexL: 1,
      loading: false,
      titles: [],
      category: [],
      bannerHeight: 0,
      advertesPicture: [],
      cakeForm: {
        cardId: '',
        current: 1, // 当前页码
        size: 10, // 每页个数
        category1Id: '',
        category2Id: '',
        cityCode: '',
        upgradeFlag: '',
        bathId: '',
        id: '',
        hotType: '',
      },
      cityCode: '',
      city: null,
      value: null,
      // 定位
      boo: true,
      address: null,
      // 非搜索状态
      boo2: true,
      // 搜索 取消
      boo3: true,
      // 刷新
      isLoading: false,
      headData: [
      ],
      lableData: [],
      entorcode: [],
      dquData: [],
      upgradeFlag: '',
      bathId: '',
      // loadFinish: false
    };
  },
  components: {
    NavImage,
    Recommended,
    HeadChild,
    IndexFloor,
    Seachview,
    HeadIndex,
    NaigateTool,
    LableImage,
    GoodsListCom,
    vueWaterfallEasy,
    CakaTab
  },
  methods: {
    loadmore(index) {
      console.log('index', index)
    },
    getBanner() {
      let form = {
        cardId: this.cakeForm.cardId,
        cityCode: this.cityCode,
        upgradeFlag: this.upgradeFlag,
        bathId: this.bathId,
        hotType: "A",
        showPositionCode: "1"
      }

      this.$api
        .getQueryRotation(form)
        .then((res) => {
          this.headData = res.data
        })
        .catch((err) => {
        });
    },
    getQueryRotation() {
      let form = {
        cardId: this.cakeForm.cardId,
        cityCode: this.cityCode,
        upgradeFlag: this.upgradeFlag,
        bathId: this.bathId,
        hotType: "D",
        showPositionCode: "1"
      }
      this.$api
        .getQueryRotation(form)
        .then((res) => {
          // this.category = res.data
          this.category = []
          this.dquData = []
          if (res.data) {
            res.data.forEach(element => {
              if (element.region == 'B') {
                this.category.push(element)
              } else if (element.region == 'D') {
                let cakaInfo = {
                  name: element.name,
                  description:element.description,
                  id: element.showImgId.id,
                  // isShowD: true,
                }
                this.dquData.push(cakaInfo)

              }

            });
            if (this.dquData.length > 0) {
              this.cakeForm.id = this.dquData[0].id
              this.cakeForm.hotType = 'D'
              this.getGoodList()
            }
          }
          // this.getData()
        })
        .catch((err) => {
        });
    },
    getGoodList() {
      this.cakeForm.cityCode = this.cityCode
      this.cakeForm.bathId = this.bathId
      this.cakeForm.upgradeFlag = this.upgradeFlag
      this.$api
        .queryProductInfoHomeNew(this.cakeForm)
        .then((res) => {
          if (res.code == 1) {
            Toast(res.msg)
            return
          }
          if (this.cakeForm.current == 1) {
            this.list = res.data.records
          } else {
            this.list.push(...res.data.records)
          }
          this.loading = false;
          this.loading2 = false;
          if (this.cakeForm.current >= res.data.pages) {
            this.finished = true
          }

        })
        .catch((err) => {
          this.loading = false;
          this.loading2 = false;
        });
    },
    showToast() {
      this.$Toast("敬请期待！");
    },
    goDetail(val) {
      //  传递参数
      this.$router.push({
        path: "/Details",
        query: {
          id: val.id,
          brandId: val.brandId,
        },
      });
    },

    scroll(e) {
    },
    nextPage() { // 加载下一页
      if (this.list.length >= 10) {
        this.loading = false
        this.cakeForm.current += 1

        this.getGoodList()
      } else {
        this.loading2 = false
      }
    },
    getTabIndex(index) {
      console.log('index   ',index);
      this.cakeForm.id = this.dquData[index].id
      this.cakeForm.category2Id = ''
      this.cakeForm.hotType = 'D'
      this.cakeForm.current = 1
      this.loading = true
      this.finished = false

      var timer = setInterval(() => {
        this.getGoodList()
        clearInterval(timer);
      }, 300)
    },
    getQueryLabel() {
      let form = {
        cardId: this.cakeForm.cardId,
        cityCode: this.cityCode,
        upgradeFlag: this.upgradeFlag,
        bathId: this.bathId,
        hotType: "B",
        showPositionCode: "1"
      }

      this.$api
        .getQueryRotation(form)
        .then((res) => {
          this.lableData = res.data
        })
        .catch((err) => {
        });
    },
    // 位置选择
    Switch() {
      // this.$router.push("Classification");
      this.$router.push("SelectAdderss");
    },
    // 调用子组件的方法
    seach() {
      this.$refs.seach.seach(this.value);
      this.boo3 = false;
    },
    // 取消
    delSeach() {
      this.value = null;
      this.boo3 = true;
      this.$refs.seach.history = true;
      this.$refs.seach.getHistroy();
    },
    // 输入框获取焦点
    seach1() {
      this.$router.push('SearchGoods')
    },

    // 返回
    goback() {
      this.boo2 = true;
      this.value = null
    },
    //子组件分发的事件  点击历史记录重新给输入框赋值
    seachvalue(val) {
      this.value = val;
    },
    repaints() {
      this.$refs.waterfall.repaints(0, 0.4);
    },
    initView() {
      this.value = null
      this.boo2 = true
      this.upgradeFlag = localStorage.getItem("upgradeFlag");
      this.bathId = localStorage.getItem("bathId");
      this.cakeForm.cardId = localStorage.getItem("cardId")
      this.cityCode = localStorage.getItem('cityCode')
      this.entorcode = localStorage.getItem('entrCode')
      this.bannerHeight = (document.body.clientWidth || window.innerWidth || document.documentElement.clientWidth) / 4 * 3

      this.getBanner()
      this.getQueryLabel()
      this.getQueryRotation()
      // // 是否有手动选择地址
      this.address = localStorage.getItem("address")

    }
  },
  activated() {
    const scrollTop = this.$route.meta.scrollTop;
    const $wrapper = document.querySelector('.app-wrapper');
    if (scrollTop && $wrapper) {
      $wrapper.scrollTop = scrollTop;
    }
  },

  mounted() {

  },
  inject: ["reload"],
  beforeRouteEnter(to, from, next) {
    to.meta.keepAlive = true
    if (from.path !== "/Details" && from.path !== "/SearchGoods") {
      next((vm) => {
        vm.$nextTick(() => {
          vm.initView()
          document.documentElement.scrollTop = 0;
          document.body.scrollTop = 0
        })
      });
    } else {
      initViewfinish = true
      next();
    }
  },
  created() {
    if (initViewfinish) {
      initViewfinish = false
      this.initView()
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0
    }
  },

  // beforeRouteLeave(to, from, next) {
  //   if (to.path === '/Details') {
  //     from.meta.keepAlive = true
  //   } else {
  //     from.meta.keepAlive = false
  //   }
  //   next()
  // },
  computed: {},
  watch: {
    // 输入框的值
    value(val, oldval) {
      if (oldval !== "") {
        this.boo3 = true;
      }
    },
    // 地址
    address() {
      return this.$store.state.address;
    },
    '$route': function () {
      this.$waterfall.forceUpdate()
    }
  },

};
</script>

<style lang='scss' scoped>
#container {
  width: 100%;
  position: absolute;
  top: 0.8rem;
  bottom: 0.5rem;
  background: #f1f1f1;
  font-size: 0.26rem;
}

.container-water-fall {

  /* // padding: 0 28px; */
  padding: 0 3%;
  width: 100vw;
  box-sizing: border-box;
  /* background: #fafafa !important; */
}

.cell-item {
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  box-sizing: border-box;
  margin: 5% 0;
}

.cell-item img {
  width: 100%;
  height: auto;
  display: block;
}

.head {
  padding: .9rem 1%;
  height: 1.25rem;
  width: 98%;
  color: white;
  z-index: 2;
}

.head2 {
  padding: .9rem 1%;
  height: 1.25rem;
  width: 98%;
  color: white;
  background-image: linear-gradient(180deg,
      #6BC8EA,
      #6BC8EA);
  z-index: 2;
}

// .container {
//   width: 100%;
//   height: 100vh;
//   background-color: #F8F8F8;
// }

.grayb {
  background-image: linear-gradient(top, #0909094e, #09090907);
  background-size: 100% 20rem;
  z-index: 1;
  position: absolute;
  height: 20rem;
  width: 100%;
}

.van-search__content {
  background-color: rgb(255, 255, 255, 0);
}

.empty {
  transform: translateY(50%);
}

/*瀑布流对应布局开始*/
.main {
  height: max-height;
  flex: auto;
  overflow-y: auto;
  overflow-x: hidden;

  /*瀑布流对应布局开始*/
  .waterfall-item {
    margin: 5px;
    background: white;
    box-sizing: border-box;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    padding-bottom: 1rem;
  }

  .waterfall-item img {
    width: 100%;
    display: block;
  }

  .bounceIn {
    animation-name: bounceIn;
    animation-duration: 0.6s;
    animation-timing-function: linear;
    transform-origin: center center;
  }

  @keyframes bounceIn {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.3);
      -ms-transform: scale(0.3);
      transform: scale(0.3);
    }

    50% {
      opacity: 1;
      -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
      transform: scale(1.05);
    }

    70% {
      -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
      transform: scale(0.9);
    }

    100% {
      opacity: 1;
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }
  }
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background-color: none;
}

::-webkit-scrollbar-thumb {
  background-color: none;
}

::-webkit-scrollbar-thumb:hover {
  background-color: none;
}

::-webkit-scrollbar-thumb:active {
  background-color: none;
}

/*瀑布流对应布局结束*/
</style>